import { z } from "zod";
import { zMinMaxShortText, zNumericId, zShortText } from "./baseschemas";
import { zUserBasicDto } from "./users";
export const zSupplier = z.object({
    id: z.number(),
    name: zMinMaxShortText,
    createdBy: zUserBasicDto,
    createdAt: z.date(),
    supplierNumber: z.string().optional().nullable(),
    lastIdeaAt: z.date().optional().nullable(),
    lastIdea: z.number().optional().nullable(),
});

export const zSupplierList = z.array(zSupplier);
export const zAdminSupplierList = z.array(zSupplier);

export const zCreateSupplierRequestBody = z.object({
    name: zShortText,
    supplierNumber: z.string().optional(),
});

export const zCreateSupplierAdmin = zCreateSupplierRequestBody.extend({});

export const zUpdateSupplierAdmin = z.object({
    name: z.string().optional(),
    supplierNumber: z.string().optional(),
});

export const zUpdateSupplierParamAdmin = z.object({
    id: zNumericId,
});

export type SupplierDto = z.infer<typeof zSupplier>;
export type AdminSupplierDto = z.infer<typeof zSupplier>;
export type SupplierListDto = z.infer<typeof zSupplierList>;
export type AdminSupplierListDto = z.infer<typeof zAdminSupplierList>;
export type CreateSupplierRequestBody = z.infer<typeof zCreateSupplierRequestBody>;
export type CreateSupplierAdminDto = z.infer<typeof zCreateSupplierAdmin>;
export type UpdateSupplierAdminDto = z.infer<typeof zUpdateSupplierAdmin>;
export type UpdateSupplierAdminParamDto = z.infer<typeof zUpdateSupplierParamAdmin>;
