import { z } from "zod";
import { AclNamespaces, AclPermissions, zAclRule } from "../constants";
import { zNumericId } from "./baseschemas";

export const zAclSimpleRule = z
    .object({
        fact: z.literal("id"),
        operator: z.literal("equal"),
        value: zNumericId,
    })
    .array()
    .length(1);

export const zBaseAcl = z.object({
    id: zNumericId,
    namespace: z.nativeEnum(AclNamespaces),
    rule: zAclRule,
    permission: z.nativeEnum(AclPermissions),
    simpleEntityId: zNumericId.nullable(),
});

export const zUserAcl = zBaseAcl.extend({
    userId: zNumericId,
});

export const zGroupAcl = zBaseAcl.extend({
    groupId: zNumericId,
});

export const zAclListDto = z.object({
    userAcls: z.array(zUserAcl),
    groupAcls: z.array(zGroupAcl),
});

export const zAclRequestParam = z.object({
    aclId: zNumericId,
});

export const zCreateAclsRequestBody = z.object({
    acl: zBaseAcl.omit({ id: true }),
    userIds: z.array(zNumericId),
    groupIds: z.array(zNumericId),
});

export const zPermissionRequestParams = z.object({
    id: zNumericId,
});

export const zPermissionRequestBody = z.object({
    permission: z.nativeEnum(AclPermissions),
});

export const zEntityPermissionRequestBody = zPermissionRequestBody.extend({
    namespace: z.nativeEnum(AclNamespaces),
    entityIds: z.array(zNumericId),
    userIds: z.array(zNumericId),
});

export const zNamespacePermissionFactRequestBody = z.object({
    namespace: z.nativeEnum(AclNamespaces),
    permission: z.nativeEnum(AclPermissions),
    fact: z.record(z.unknown()).default({}),
});

export const zPermissionFactRequestBody = z.object({
    permission: z.nativeEnum(AclPermissions),
    fact: z.record(z.unknown()).default({}),
});

export const zPermissionRequestQuery = z.object({
    showAllRules: z.preprocess((val) => val === "true", z.boolean()).default(false),
    namespaces: z.string().optional(),
    permissions: z.string().optional(),
});

export const zPermissionsDto = z.object({
    combinedUserIds: z.array(zNumericId),
    userIds: z.array(zNumericId),
    groupIds: z.array(zNumericId),
    userAcls: z.array(zUserAcl),
    groupAcls: z.array(zGroupAcl),
});

export const zSimplePermissionsDto = z.object({
    combinedUserIds: z.array(zNumericId),
    userIds: z.array(zNumericId),
    groupIds: z.array(zNumericId),
    userAcls: z.array(
        z.object({
            id: zNumericId,
            userId: zNumericId,
            namespace: z.nativeEnum(AclNamespaces),
            permission: z.nativeEnum(AclPermissions),
            simpleEntityId: zNumericId.nullable(),
        }),
    ),
    groupAcls: z.array(
        z.object({
            id: zNumericId,
            groupId: zNumericId,
            namespace: z.nativeEnum(AclNamespaces),
            permission: z.nativeEnum(AclPermissions),
            simpleEntityId: zNumericId.nullable(),
        }),
    ),
});

export const zPermissionBasicRequestParams = z.object({
    entityId: zNumericId,
    id: zNumericId,
});

export const zPermissionPermissionRequestParams = z.object({
    entityId: zNumericId,
    id: zNumericId,
    permission: z.nativeEnum(AclPermissions),
});

export const zPermissionPermissionRequestBody = z.object({
    id: zNumericId,
    permission: z.nativeEnum(AclPermissions),
});

export const zBasicPermissionDto = z.object({
    hasPermission: z.boolean(),
});

export type AclSimpleRule = z.infer<typeof zAclSimpleRule>;
export type UserAclDto = z.infer<typeof zUserAcl>;
export type GroupAclDto = z.infer<typeof zGroupAcl>;
export type AclListDto = z.infer<typeof zAclListDto>;
export type PermissionsDto = z.infer<typeof zPermissionsDto>;
export type BasicPermissionDto = z.infer<typeof zBasicPermissionDto>;
export type SimplePermissionsDto = z.infer<typeof zSimplePermissionsDto>;
export type CreateAclsRequestBody = z.infer<typeof zCreateAclsRequestBody>;
export type CreateAcls = z.infer<typeof zCreateAclsRequestBody>;
export type PermissionRequestBody = z.infer<typeof zPermissionRequestBody>;
export type EntityPermissionRequestBody = z.infer<typeof zEntityPermissionRequestBody>;
export type PermissionFactRequestBody = z.infer<typeof zPermissionFactRequestBody>;
export type PermissionBasicRequestParams = z.infer<typeof zPermissionBasicRequestParams>;
export type PermissionPermissionRequestParams = z.infer<typeof zPermissionPermissionRequestParams>;
export type PermissionPermissionRequestBody = z.infer<typeof zPermissionPermissionRequestBody>;
export type NamespacePermissionFactRequestBody = z.infer<typeof zNamespacePermissionFactRequestBody>;
