export enum HistoryEventType {
    INSERT = 1, // insert new object
    UPDATE = 2, // update from one value to another
    DELETE = 3, // completely delete object
    FILL = 4, // fill a value which was unset (null) before
    CLEAR = 5, // remove a value which was set (!= null) before
}

export enum HistoryParent {
    MEASURE = "measure",
    SUBTASK = "subtask",
}

export enum CalculationHistoryType {
    EffectCategory = "effectCategory",
    Generation = "generation",
    Effect = "effect",
}

export enum IdeaHistoryTable {
    Idea = "idea",
    IdeaValue = "idea_value",
}
