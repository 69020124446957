import { z } from "zod";
import { isValidPassword } from "../../passwords";
import { zMaxInt, zMinMaxShortText, zNumericId } from "../baseschemas";
import { zClientDto } from "../clients";

export const zSuperAdminClientListDto = z.array(zClientDto);

export const zSuperAdminRequestQueryParams = z.object({
    onlyCustomers: z.coerce.boolean().optional(),
});

const zClientRequestBody = z.object({
    name: z.string().trim().min(1),
    alias: z.string(),
    duns: zMaxInt,
    isCustomer: z.coerce.boolean(),
    adminUserSeats: zMaxInt.nullable(),
    advancedUserSeats: zMaxInt.nullable(),
    basicUserSeats: zMaxInt.nullable(),
    lightUserSeats: zMaxInt.nullable(),
    currencyName: zMinMaxShortText,
    currencyIsoCode: z.string().min(1).max(3),
    fiscalYear: z.coerce.number().int().min(0).max(11),
    fiscalYearRangePast: z.coerce.number().int(),
    fiscalYearRangeFuture: z.coerce.number().int(),
    timezone: z.string().trim().min(1),
    ideaPotentialIntervals: z.array(z.number().int()).min(4).max(4),
    features: z.record(z.string(), z.number()),
});

export const zCreateClientRequestBody = z.object({
    newClient: zClientRequestBody,
    initialUser: z.discriminatedUnion("createInitialUser", [
        z.object({
            createInitialUser: z.literal(true),
            realname: z.string().trim().min(1),
            email: z.string().trim().email(),
            password: z.string().refine(isValidPassword),
        }),
        z.object({
            createInitialUser: z.literal(false),
            realname: z.literal(""),
            email: z.literal(""),
            password: z.literal(""),
        }),
    ]),
});

export const zUpdateClientRequestBody = zClientRequestBody.omit({ currencyName: true, currencyIsoCode: true }).extend({ id: zNumericId });

export const zMergeClientsRequestBody = z.object({
    primary: zNumericId,
    secondary: zNumericId,
});

export const zUploadSuppliersRequestBody = z.object({
    clientId: zNumericId,
});

export type SuperAdminClientListDto = z.infer<typeof zSuperAdminClientListDto>;
export type CreateClientRequestBody = z.infer<typeof zCreateClientRequestBody>;
export type UpdateClientRequestBody = z.infer<typeof zUpdateClientRequestBody>;
export type MergeClientsRequestBody = z.infer<typeof zMergeClientsRequestBody>;
export type UploadSuppliersRequestBody = z.infer<typeof zUploadSuppliersRequestBody>;
