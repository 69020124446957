import { z } from "zod";
import { CalculationHistoryType, EffectFilterCurrencyField, HistoryEventType } from "../constants";
import { zNumericId } from "./baseschemas";

export const zHistoryEntryDto = z.object({
    historyType: z.nativeEnum(CalculationHistoryType),
    operationType: z.nativeEnum(HistoryEventType),
    previousValue: z.string().or(z.number()).nullable(),
    newValue: z.string().or(z.number()).nullable(),
    updatedAt: z.date(),
    updatedById: zNumericId,
});

export type HistoryEntryDto = z.infer<typeof zHistoryEntryDto>;

export const zEffectCategoryHistoryEntryDto = zHistoryEntryDto.extend({
    historyType: z.literal(CalculationHistoryType.EffectCategory),
    effectCategoryId: zNumericId,
    attribute: z.string(),
    previousValue: z.string().nullable(),
    newValue: z.string(),
});

export type EffectCategoryHistoryEntryDto = z.infer<typeof zEffectCategoryHistoryEntryDto>;

export const zEffectHistoryEntryDto = zHistoryEntryDto.extend({
    historyType: z.literal(CalculationHistoryType.Effect),
    generationId: zNumericId,
    effectType: z.nativeEnum(EffectFilterCurrencyField),
    month: z.number(),
    year: z.number(),
    previousValue: z.number().nullable(),
    newValue: z.number().nullable(),
});

export type EffectHistoryEntryDto = z.infer<typeof zEffectHistoryEntryDto>;

export const zGenerationHistoryEntryDto = zHistoryEntryDto.extend({
    historyType: z.literal(CalculationHistoryType.Generation),
    generationId: zNumericId,
    attribute: z.string(),
    previousValue: z.string().nullable(),
    newValue: z.string().nullable(),
});
export type GenerationHistoryEntryDto = z.infer<typeof zGenerationHistoryEntryDto>;

export const zEffectCategoryHistoryDto = z.object({
    items: z.array(
        z.discriminatedUnion("historyType", [zEffectCategoryHistoryEntryDto, zGenerationHistoryEntryDto, zEffectHistoryEntryDto]),
    ),
});

export type EffectCategoryHistoryDto = z.infer<typeof zEffectCategoryHistoryDto>;
