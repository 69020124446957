import { z } from "zod";
import { EffectFilterCurrencyField } from "../constants";
import { zNumericId } from "./baseschemas";

export const zEffectDto = z.object({
    value: z.number(),
    inputValue: z.number(),
    month: z.number(),
    year: z.number(),
});

export type EffectDto = z.infer<typeof zEffectDto>;

export const zEffectSeriesDto = z.object({
    effectCategoryId: zNumericId,
    generationId: zNumericId,
    type: z.nativeEnum(EffectFilterCurrencyField),
    effects: z.array(zEffectDto),
});

export type EffectSeriesDto = z.infer<typeof zEffectSeriesDto>;

export const zEffectSeriesListDto = z.array(zEffectSeriesDto);

export type EffectSeriesListDto = z.infer<typeof zEffectSeriesListDto>;

export const zEffectSeriesDeleteDto = z.object({
    generationId: zNumericId,
    type: z.nativeEnum(EffectFilterCurrencyField),
    month: z.number().optional(),
    year: z.number().optional(),
    duration: z.number().optional(),
});

export type EffectSeriesDeleteDto = z.infer<typeof zEffectSeriesDeleteDto>;

export const zEffectSeriesUpdateDto = z.object({
    generationId: zNumericId,
    type: z.nativeEnum(EffectFilterCurrencyField),
    value: z.number(),
    month: z.number(),
    year: z.number(),
    duration: z.number(),
});

export type EffectSeriesUpdateDto = z.infer<typeof zEffectSeriesUpdateDto>;
