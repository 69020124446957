export enum EstimatesValue {
    VERY_LOW = 10,
    LOW = 20,
    MEDIUM = 30,
    HIGH = 40,
    VERY_HIGH = 50,
}

export enum IdeaSortBy {
    DISPLAY_ID = "displayId",
    OWNER_ID = "ownerId",
    TITLE = "title",
    CREATED_AT = "createdAt",
    POTENTIAL = "potentialEstimate",
    TIME = "timeEstimate",
    EFFORT = "effortEstimate",
    LAST_MODIFICATION_AT = "lastModificationAt",
}

export enum IdeaXAxis {
    TIME = "timeEstimate",
    EFFORT = "effortEstimate",
}

export enum IdeaStatus {
    OPEN = "open",
    CONVERTED = "converted",
    DISCARDED = "discarded",
}

export const DEFAULT_POTENTIAL_INTERVALS = [5000, 15000, 50000, 150000];
