import { z } from "zod";
import { Language, UserLanguage, UserStatus, UserTier } from "../constants";
import { zMinMaxShortText, zNumericId, zShortText } from "./baseschemas";

export const zCreateUserRequestBody = z.object({
    email: z.string().trim().email(),
    metadata: z.object({
        measureId: z.number().int(),
    }),
});

export const zGenerateApiTokenRequestBody = z.object({
    period: z.number().min(1).max(365),
});

export const zApiToken = z.object({
    token: z.string(),
    expiresAt: z.date(),
});

export const zApiTokenMeta = z.object({
    expiresAt: z.date().nullable(),
});

export const zLoginBase = z.object({
    email: z.string().min(1),
    password: z.string().min(1),
});

export const zLogin = zLoginBase.extend({
    twoFactorAuthenticationToken: z.string().optional(),
    twoFactorAuthenticationRemember: z.preprocess((b) => (b != undefined ? Boolean(b) : undefined), z.boolean().optional()),
});

export const zLoginTwoFactorAuthenticationVerify = zLoginBase.extend({
    twoFactorAuthenticationToken: z.string().min(1),
});

export const zOtpauth = z.object({
    success: z.boolean(),
    otpauthUrl: z.string(),
});

export type CreateUserRequestBody = z.infer<typeof zCreateUserRequestBody>;
export type GenerateApiTokenRequestBody = z.infer<typeof zGenerateApiTokenRequestBody>;
export type ApiTokenDto = z.infer<typeof zApiToken>;
export type ApiTokenMetaDto = z.infer<typeof zApiTokenMeta>;
export type LoginBaseDto = z.infer<typeof zLoginBase>;
export type LoginDto = z.infer<typeof zLogin>;
export type LoginTwoFactorAuthenticationVerifyDto = z.infer<typeof zLoginTwoFactorAuthenticationVerify>;
export type OtpauthDto = z.infer<typeof zOtpauth>;

/**
 * This DTO is intended for CROSS CLIENT user information (System, ...).
 */
export const zUserBasicDto = z.object({
    id: zNumericId,
    email: z.string().nullable(),
    realname: z.string().nullable(),
    displayname: z.string().nullable(),
    imageHash: z.string().nullish(),
    department: z.string().nullable(),
    position: z.string().nullable(),
    status: z.nativeEnum(UserStatus),
    mobileNumber: z.string().nullable(),
    companyId: zNumericId.nullable(),
});

export type UserBasicDto = z.infer<typeof zUserBasicDto>;

export const zUserDto = z.object({
    id: zNumericId,
    clientId: zNumericId,
    email: z.string().nullable(),
    realname: z.string().nullable(),
    displayname: z.string().nullable(),
    imageHash: z.string().nullish(),
    telephoneNumber: z.string().nullable(),
    mobileNumber: z.string().nullable(),
    department: z.string().nullable(),
    position: z.string().nullable(),
    currencyId: zNumericId.nullable(),
    status: z.nativeEnum(UserStatus),
    timezone: z.string().nullish(),
    createdAt: z.date(),
    language: z.string().nullable(),
    enabledNotifications: z.string().nullish(),
    invitedAt: z.date().nullable(),
    identityProviderId: zNumericId.nullable(),
    companyId: zNumericId.nullable(),
    twoFactorAuthenticationValidated: z.boolean().nullable(),
    groups: z.number().array(),
    directGroups: z.number().array(),
    tier: z.nativeEnum(UserTier),
});

export type UserDto = z.infer<typeof zUserDto>;

export const zUserListDto = z.array(zUserDto);

export type UserListDto = z.infer<typeof zUserListDto>;

export const zUpdateCurrentUserRequestBody = z.object({
    realname: zShortText.nullish(),
    displayname: zShortText.nullish(),
    telephoneNumber: zShortText.nullish(),
    mobileNumber: zShortText.nullish(),
    department: zShortText.nullish(),
    position: zShortText.nullish(),
    currencyId: zNumericId.nullish(),
    timezone: zMinMaxShortText.nullish(),
    companyId: zNumericId.nullish(),
    language: z.nativeEnum(Language).optional(),
});

export type UpdateCurrentUserRequestBody = z.infer<typeof zUpdateCurrentUserRequestBody>;

export const zUpdateUser = z.object({
    realname: zShortText.nullish(),
    displayname: zShortText.nullish(),
    telephoneNumber: zShortText.nullish(),
    mobileNumber: zShortText.nullish(),
    department: zShortText.nullish(),
    position: zShortText.nullish(),
    currencyId: zNumericId.nullish(),
    timezone: zMinMaxShortText.nullish(),
    companyId: zNumericId.nullish(),
    language: z.union([z.nativeEnum(Language).optional(), z.custom<UserLanguage>()]).optional(),
    status: z.nativeEnum(UserStatus).optional(),
    activationToken: z.string().nullish(),
    tier: z.nativeEnum(UserTier).optional(),
    invitedAt: z.date().nullish(),
    inviteToken: z.string().nullish(),
    passwordHash: z.string().nullish(),
    authKey: z.string().optional(),
});

export type UpdateUserBo = z.infer<typeof zUpdateUser>;
