import { z } from "zod";
import { CalculationType } from "../constants";
import { zDateString, zNumericId } from "./baseschemas";

const zGenerationInputField = z.object({
    title: z.string(),
    type: z.string(),
    value: z.any(),
    formula: z.string().nullable().optional(),
    order: z.number().nullable(),
    mandatory: z.boolean().optional(),
    calculated: z.boolean().optional(),
});

export type GenerationInputField = z.infer<typeof zGenerationInputField>;

export const zGenerationDto = z.object({
    id: zNumericId,
    target: z.number().nullable(),
    effect: z.number().nullable(),
    initial: z.number().nullable(),
    priceHike: z.number().nullable(),
    startDate: zDateString.nullable(),
    endDate: zDateString.nullable(),
    hasInitial: z.boolean(),
    effectCategoryId: zNumericId,
    gateTaskId: zNumericId,
    inputFields: z.record(zGenerationInputField),
    calculationType: z.nativeEnum(CalculationType),
});

export type GenerationDto = z.infer<typeof zGenerationDto>;

export const zGenerationListDto = z.array(zGenerationDto);

export type GenerationListDto = z.infer<typeof zGenerationListDto>;

const zDateRange = z.object({
    start: z.date().nullable(),
    end: z.date().nullable(),
});

export type IDateRange = z.infer<typeof zDateRange>;

export const zGenerationUpdateDto = z.record(z.union([z.string(), z.number(), zDateRange, z.boolean(), z.null(), z.undefined()]));

export type GenerationUpdateDto = z.infer<typeof zGenerationUpdateDto>;
