import { z } from "zod";
import { UserTier } from "../../constants";
import { zHexColor } from "../baseschemas";

export const zClientAdminUpdateDto = z.object({
    name: z.string().trim().max(255).optional(),
    internalContactId: z.number().nullable().optional(),
    defaultUserTier: z.nativeEnum(UserTier).optional(),
    externalUserGroupId: z.number().nullable().optional(),
    externalUserTier: z.nativeEnum(UserTier).optional(),
    defaultGroupId: z.number().nullable().optional(),
    dashboardColors: z.array(zHexColor).min(12).nullable().optional(),
    whiteSpotColor: zHexColor.nullable().optional(),
    defaultMeasureGroupId: z.number().nullable().optional(),
});

export type ClientAdminUpdateDto = z.infer<typeof zClientAdminUpdateDto>;
