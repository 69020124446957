import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import {
    CurrentGateType,
    MeasureCalculationGranularity,
    MeasureCopyOptions,
    MeasureNotificationActions,
    MeasureNotificationState,
    MeasureStatus,
    Sort,
} from "../constants";
import { zMinMaxString, zNumericId, zShortText } from "./baseschemas";
import { zCommentDto } from "./comments";
import { zEffectCategoryDto, zUpdateEffectCategoryDto } from "./effect-categories";
import { zFilterDefinition } from "./filters";
import { zGateTaskDto } from "./gate-tasks";
import { zMeasureConfigDto } from "./measure-configs";
import { zScopeDto } from "./scope";
import { zUserBasicDto } from "./users";

export const zCreateMeasureRequestBody = extendApi(
    z.object({
        title: extendApi(zMinMaxString, { example: "Make packaging thinner." }),
        measureConfigId: z.number(),
        methodId: z.number().optional(),
        description: z.string().optional(),
        sourceMeasureId: zNumericId.optional(),
        copyOptions: z.enum(MeasureCopyOptions).array().optional(),
    }),
    {
        title: "CreateMeasure",
        description: "A create measure schema",
    },
);

const zBaseMeasureSearchRequestBody = z.object({
    filter: zFilterDefinition.optional(),
    filterId: zNumericId.optional(),
    scope: zScopeDto.optional(),
    measureConfigIds: zNumericId.array().optional(),
});

const zBaseMeasureSearchSortableRequestBody = zBaseMeasureSearchRequestBody.extend({
    orderBy: zShortText.optional(),
    sort: z.nativeEnum(Sort).optional(),
});

export const zMeasuresPotentialRequestBody = zBaseMeasureSearchRequestBody.extend({
    byCategory: z.nativeEnum(CurrentGateType).optional(),
});

export const zMeasureIdSearchRequestBody = zBaseMeasureSearchSortableRequestBody.extend({});

export const zMeasureSearchRequestBody = zBaseMeasureSearchSortableRequestBody.extend({
    page: z.number().int().min(0).optional(),
    pageSize: z.number().int().min(1).optional(),
});

export const zMeasurePotentialSums = z.object({
    matchingItems: z.number().min(0),
    sums: z.record(z.string(), z.number()),
});

export type CreateMeasureRequestBody = z.infer<typeof zCreateMeasureRequestBody>;
export type MeasureIdSearchRequestBody = z.infer<typeof zMeasureIdSearchRequestBody>;
export type MeasureSearchRequestBody = z.infer<typeof zMeasureSearchRequestBody>;
export type MeasuresPotentialRequestBody = z.infer<typeof zMeasuresPotentialRequestBody>;
export type MeasurePotentialSums = z.infer<typeof zMeasurePotentialSums>;

export const zInvalidMeasurePropertiesDto = z.object({
    fields: z.array(z.number()),
    calculations: z.record(z.array(z.number())),
    invalidGates: z.array(z.number()),
    incompleteGates: z.array(z.number()),
});

export type InvalidMeasurePropertiesDto = z.infer<typeof zInvalidMeasurePropertiesDto>;

export const zMeasureConversionRateRequestBody = z.object({
    measureConfigId: zNumericId,
    filter: zFilterDefinition,
    scope: zScopeDto,
});

export const zMeasureConversionRateDto = z.object({
    gateConversions: z.record(z.number()),
});

export type MeasureConversionRateDto = z.infer<typeof zMeasureConversionRateDto>;

export const zMeasureFavoriteDto = z.object({
    measureId: zNumericId,
    userId: zNumericId,
    createdById: zNumericId,
    updatedById: zNumericId,
    createdAt: z.date(),
    updatedAt: z.date(),
});

export type MeasureFavoriteDto = z.infer<typeof zMeasureFavoriteDto>;

export const zMeasureHistoryDto = z.object({
    id: zNumericId,
    parentId: zNumericId,
    userId: zNumericId.nullable(),
    datetime: z.date(),
    type: z.number(),
    previousValue: z.string().nullable(),
    newValue: z.string().nullable(),
    attribute: z.string(),
    tableName: z.string(),
    dataId: zNumericId.nullish(),
});

export const zMeasureHistoryListDto = z.array(zMeasureHistoryDto);

export type MeasureHistoryListDto = z.infer<typeof zMeasureHistoryListDto>;

export type MeasureHistoryDto = z.infer<typeof zMeasureHistoryDto>;

export const zMeasureNotificationDto = z.object({
    id: zNumericId,
    measureId: zNumericId,
    userId: zNumericId,
    createdById: zNumericId,
    createdAt: z.date(),
    updatedById: zNumericId,
    updatedAt: z.date(),
    state: z.nativeEnum(MeasureNotificationState),
});

export type MeasureNotificationDto = z.infer<typeof zMeasureNotificationDto>;

export const zGetMeasureNotificationsResponseDto = z.array(zMeasureNotificationDto);

export type GetMeasureNotificationsResponseDto = z.infer<typeof zGetMeasureNotificationsResponseDto>;

export const zMeasureNotificationCreateDto = z.object({
    userId: zNumericId,
});

export type MeasureNotificationCreateDto = z.infer<typeof zMeasureNotificationCreateDto>;

export const zMeasureNotificationUpdateDto = z.object({
    userId: zNumericId,
    action: z.nativeEnum(MeasureNotificationActions),
});

export type MeasureNotificationUpdateDto = z.infer<typeof zMeasureNotificationUpdateDto>;

export const zMeasureValueDto = z.object({
    id: zNumericId,
    measureAttributeId: zNumericId,
    measureId: zNumericId,
    value: z.string().nullable(),
    updatedAt: z.date(),
    updatedById: zNumericId,
    createdAt: z.date(),
    createdById: zNumericId,
});

export type MeasureValueDto = z.infer<typeof zMeasureValueDto>;

export const zMeasureDto = z.object({
    id: zNumericId,
    title: z.string(),
    clientIid: zNumericId,
    clientId: zNumericId,
    status: z.nativeEnum(MeasureStatus),
    assignedToId: zNumericId.nullable(),
    completedGateTaskConfigId: zNumericId.nullable(),
    currentGateTaskConfigId: zNumericId.nullable(),
    calculationGranularity: z.nativeEnum(MeasureCalculationGranularity),
    currencyId: zNumericId,
    updatedById: zNumericId,
    updatedAt: z.date(),
    createdById: zNumericId,
    createdBy: zUserBasicDto,
    createdAt: z.date(),
    lastModificationAt: z.date(),
    completedById: zNumericId.nullable(),
    completedAt: z.date().nullable(),
    gateTasks: z.array(zGateTaskDto),
    effectCategories: z.array(zEffectCategoryDto),
    comments: z.array(zCommentDto).optional(),
    invalidProperties: zInvalidMeasurePropertiesDto,
    measureConfig: zMeasureConfigDto,
    measureConfigId: zNumericId,
    ideaId: z.number().nullable(),
    fields: z.record(z.record(z.any())),
    isFavorite: z.boolean(),
});

export type MeasureDto = z.infer<typeof zMeasureDto>;

export const zMeasureUpdate = z
    .object({
        title: z.string().nullable().optional(),
        assignedTo: zNumericId.nullable().optional(),
        calculationGranularity: z.nativeEnum(MeasureCalculationGranularity).optional(),
        currencyId: zNumericId.optional(),
        effectCategories: z.record(zUpdateEffectCategoryDto).optional(),
    })
    .catchall(z.union([z.string(), z.number(), z.array(z.number()), z.null()]));

export type MeasureUpdate = z.infer<typeof zMeasureUpdate>;

// flat measure dto without any joined relations can be used when measure is joined in a different entity, but no relations/fields/... are needed
export const zFlatMeasureDto = z.object({
    id: zNumericId,
    title: z.string(),
    clientIid: zNumericId,
    clientId: zNumericId,
    status: z.nativeEnum(MeasureStatus),
    assignedToId: zNumericId.nullable(),
    completedGateTaskConfigId: zNumericId.nullable(),
    currentGateTaskConfigId: zNumericId.nullable(),
    // Actually the gateTasks should not be contained in a Flat*Dto, but there is no separate endpoint to fetch them from
    // SubTaskDto (where the FlatMeasureDto is used) requires gateTasks to be set so the level badge can be shown
    // decisionTypes are not needed but their computation is complex, so skip those here
    gateTasks: z.array(zGateTaskDto.omit({ decisionTypes: true })),
    calculationGranularity: z.nativeEnum(MeasureCalculationGranularity),
    currencyId: zNumericId,
    updatedById: zNumericId,
    updatedAt: z.date(),
    createdById: zNumericId,
    createdAt: z.date(),
    lastModificationAt: z.date(),
    completedById: zNumericId.nullable(),
    completedAt: z.date().nullable(),
    measureConfigId: zNumericId,
    ideaId: z.number().nullable(),
});

export type FlatMeasureDto = z.infer<typeof zFlatMeasureDto>;

export const zLatestViewedMeasureDto = z.object({
    id: zNumericId,
    title: z.string(),
    clientIid: zNumericId,
    lastViewedAt: z.date(),
});

export const zLatestViewedMeasuresDto = zLatestViewedMeasureDto.array();

export type LatestViewedMeasureDto = z.infer<typeof zLatestViewedMeasureDto>;
export type LatestViewedMeasuresDto = z.infer<typeof zLatestViewedMeasuresDto>;

export const zMeasureIdParams = z.object({
    id: zNumericId,
});

export const zUpdateMeasureRequestParams = z.object({
    measureId: zNumericId,
});

export const zMeasureCopyOptionsList = z.enum(MeasureCopyOptions).array();
export type MeasureCopyOptionsList = z.infer<typeof zMeasureCopyOptionsList>;
