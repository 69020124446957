import { FieldTypes } from "./FieldTypes";

/**
 * These constants are used to reference global calculation identifiers. Make sure that these identifiers always exist globally in the
 * database. Use these constants sparingly and only if it is really necessary.
 */
export enum GlobalCalculationIdentifier {
    Plan = "plan",
    Forecast = "forecast",
    Actual = "actual",
}

export enum EffectFilterDateField {
    StartDate = "startDate",
    EndDate = "endDate",
}

export enum EffectFilterCurrencyField {
    Initial = "initial",
    PriceHike = "priceHike",
    Target = "target",
    Effect = "effect",
}

export enum EffectField {
    Initial = "initial",
    PriceHike = "priceHike",
    Target = "target",
    Effect = "effect",
    StartDate = "startDate",
    EndDate = "endDate",
    HasInitial = "hasInitial",
}

export const EffectFieldTypes: Record<EffectField, string> = {
    [EffectField.Initial]: FieldTypes.Currency,
    [EffectField.PriceHike]: FieldTypes.Currency,
    [EffectField.Target]: FieldTypes.Currency,
    [EffectField.Effect]: FieldTypes.Currency,
    [EffectField.StartDate]: FieldTypes.Date,
    [EffectField.EndDate]: FieldTypes.Date,
    [EffectField.HasInitial]: FieldTypes.Boolean,
};
