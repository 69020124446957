import { z } from "zod";
import { zMinMaxString, zNumericId } from "./baseschemas";
import { zWidgetDto } from "./widgets";

export const zCreateDashboardRequestBody = z.object({
    name: zMinMaxString,
});

export const zUpdateDashboardRequestParams = z.object({
    dashboardId: zNumericId,
});

export const zUpdateDashboardRequestBody = z.object({
    name: zMinMaxString.optional(),
});

export const zDeleteDashboardRequestParams = z.object({
    dashboardId: zNumericId,
});

export const zDashboardIdParams = z.object({
    id: zNumericId,
});

export type CreateDashboardRequestBody = z.infer<typeof zCreateDashboardRequestBody>;
export type UpdateDashboardRequestParams = z.infer<typeof zUpdateDashboardRequestParams>;
export type UpdateDashboardRequestBody = z.infer<typeof zUpdateDashboardRequestBody>;
export type UpdateDashboardRequest = UpdateDashboardRequestParams & UpdateDashboardRequestBody;
export type DeleteDashboardRequestParams = z.infer<typeof zDeleteDashboardRequestParams>;
export type DuplicateDashboardRequest = UpdateDashboardRequestParams & CreateDashboardRequestBody;
export type DashboardIdParamsRequest = z.infer<typeof zDashboardIdParams>;

export const zPlainDashboardDto = z.object({
    id: zNumericId,
    userId: zNumericId,
    clientId: zNumericId,
    name: z.string(),
});

export const zDashboardDto = zPlainDashboardDto.extend({
    widgets: z.array(zWidgetDto),
});

export type PlainDashboardDto = z.infer<typeof zPlainDashboardDto>;

export type DashboardDto = z.infer<typeof zDashboardDto>;

export const zGetDashboardsResponseDto = z.array(zDashboardDto);

export const zPlainDashboardListDto = z.array(zPlainDashboardDto);

export type GetDashboardsResponseDto = z.infer<typeof zGetDashboardsResponseDto>;

export const zLatestViewedDashboardDto = z.object({
    dashboardId: zNumericId,
    lastViewedAt: z.date(),
});

export const zLatestViewedDashboardsDto = zLatestViewedDashboardDto.array();

export type LatestViewedDashboardDto = z.infer<typeof zLatestViewedDashboardDto>;
export type LatestViewedDashboardsDto = z.infer<typeof zLatestViewedDashboardsDto>;
